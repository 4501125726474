import {Injectable, OnDestroy} from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {Location} from "@angular/common";
import {Router, NavigationEnd} from "@angular/router";

import {LoginHelperService} from "../../shared/services/login-helper.service";
import {SidebarOptions} from "../../shared/models/sidebar-options";
import {
    BreakpointObserver,
    BreakpointState
} from '@angular/cdk/layout';
import {EventsService} from "../../shared/services/events.service";
import {takeUntil} from "rxjs/operators";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class NavigationHelperService implements OnDestroy {
    private _shownBar: BehaviorSubject<SidebarOptions>;
    public readonly shownBar: Observable<SidebarOptions>;
    private _currentRoute: BehaviorSubject<string>;
    public readonly currentRoute$: Observable<string>;
    private _showBackInsteadofDeskSelect: BehaviorSubject<boolean>;
    public readonly showBackInsteadofDeskSelect: Observable<boolean>;
    hideTopBar: boolean;
    hideNavigationMenu: boolean;
    showFullscreen: boolean = true;
    isNavbarOpened: boolean;
    isMediaSidebarOpened: boolean;
    showSmallNavBar: boolean;
    smallDeviceScreen: boolean;
    showOfferMobile : boolean;
    scriptScreenLoading : boolean;
    unsubscribe$ = new Subject<void>();

    private history: string[] = []
    private readonly smallNavBreakPoint = '(min-width: 1439px)';

    constructor(
        private router: Router,
        private location: Location,
        private loginHelperService: LoginHelperService,
        public breakpointObserver: BreakpointObserver,
        private eventsService: EventsService,
    ) {
        this._shownBar = new BehaviorSubject<SidebarOptions>(null);
        this.shownBar = this._shownBar.asObservable();
        this._currentRoute = new BehaviorSubject<string>("");
        this.currentRoute$ = this._currentRoute.asObservable();
        this._showBackInsteadofDeskSelect = new BehaviorSubject(false);
        this.showBackInsteadofDeskSelect = this._showBackInsteadofDeskSelect.asObservable();
        this.hideTopBar = false;
        this.hideNavigationMenu = false;
        this.isNavbarOpened = false;
        this.isMediaSidebarOpened = false;
        this.showFullscreen = true;
        this.showSmallNavBar = false;
        this.scriptScreenLoading = false;
        this.smallDeviceScreen = false;
        this.setRouterEventsListener();
        this.setMediaQListener();
    }

    /**
     Observe breakpoints
     */
    setMediaQListener = () => {
        this.breakpointObserver
            .observe([this.smallNavBreakPoint]).pipe(
            takeUntil(this.unsubscribe$)
        )
            .subscribe((state: BreakpointState) => {
                this.smallDeviceScreen = !state.breakpoints[this.smallNavBreakPoint];
                this.showSmallNavBar = this.showSmallSideNavBar();
            });
    }

    setRouterEventsListener = () => {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const url = event.urlAfterRedirects ? event.urlAfterRedirects : event.url;
                this.hideTopBar = this.shouldHideTopBar();
                this.hideNavigationMenu = this.shouldHideNavigationMenu();
                this.showFullscreen = this.shouldShowFullscreen(url);
                this.showSmallNavBar = this.showSmallSideNavBar();
                this._showBackInsteadofDeskSelect.next(this.shouldShowBackButtonInsteadOfWorkspace());
                this.setRoute(url);
                this.setHistoryRoute(event);
            }
        });
    };

    shouldShowBackButtonInsteadOfWorkspace = (): boolean => {
        return (
            this.checkLocationPath('/profile') ||
            this.checkLocationPath('/experts') ||
            this.location.path().includes('admin')
        );
    }

    setHistoryRoute = (event) => {
        if (this.history.length == 0 || (this.history.length > 0 && this.history[this.history.length - 1] !== event.urlAfterRedirects)) {
            this.history.push(event.urlAfterRedirects)
        }
    }

    showSmallSideNavBar = (): boolean => {
        return (
            this.smallDeviceScreen && (
                this.checkLocationPath('/pages/templates') ||
                this.checkLocationPath('/social-channels') ||
                this.checkLocationPath('/social-channels/accounts') ||
                this.checkLocationPath('/experts') ||
                this.checkLocationPath('/boards') ||
                this.location.path().includes('collaborate')
            ));
    }


    setRoute = (url: string) => {
        this._currentRoute.next(url);
    };

    setShownBar = (bar: SidebarOptions) => {
        this._shownBar.next(bar);
    };



    isAssetsLibraryBarOpen = (): boolean => {
        return this.getShownBar() === "assets";
    };

    isUploadsBarOpen = (): boolean => {
        return this.getShownBar() === "my-uploads";
    };

    getShownBar = (): string => {
        return this._shownBar.getValue();
    };

    closeMediaSidebar = () => {
        this.setShownBar(SidebarOptions.NONE);
    };

    toggleMediaSidebar = (barName: SidebarOptions, toggleOnClick: boolean = false) => {
        if (toggleOnClick && barName === this.getShownBar()) {
            this.closeMediaSidebar();
        } else {
            this.setShownBar(barName);
        }
    };


    toggleMediaSidebarWithResult = (barName: SidebarOptions, toggleOnClick: boolean = false) => {
        if (toggleOnClick && barName === this.getShownBar()) {
            this.closeMediaSidebar();
            return false;
        } else {
            this.setShownBar(barName);
            return true;
        }
    };

    inAdminSection = () => {
        return this.location.path().includes('admin');
    }

    shouldShowFullscreen = (route: string): boolean => {
        if (
            (this.checkLocationPath("/interactive-video") && !this.checkLocationPath("/interactive-videos")) ||
            this.checkLocationPath("/sequence") ||
            this.checkLocationPath("/pages/edit") ||
            this.checkLocationPath("/pages/create") ||
            this.checkLocationPath("/background") ||
            this.checkLocationPath("/export-video") ||
            this.checkLocationPath("/course") ||
            this.checkLocationPath("/collaborate/settings") ||
            this.checkLocationPath("/composer") ||
            this.location.path().includes("pricing") ||
            this.location.path().includes("script-story") ||
            this.checkLocationPath("/welcome") ||
            this.checkLocationPath("/onboarding")
        ) {
            return true;
        }
        return false;
    };

    onThemeEditorPage = (): boolean => {
        return this.location.path().includes("branding/theme-editor");
    };

    shouldHideWorkspaceDropdown = (): boolean => {
        return (
            this.location.path().includes("collaborate")
        );
    };

    shouldShowMinimalTopbar = (): boolean => {
        return (
            this.location.path().includes("admin") ||
            this.checkLocationPath("/collaborate/settings")
        );
    };

    shouldHideNavigationMenu = (): boolean => {
        return (
            this.shouldHideTopBar() ||
            this.checkLocationPath("/profile") ||
            this.checkLocationPath("/offboarding") ||
            this.checkLocationPath("/download-mp4")
        );
    };

    shouldShowLoginButton = (): boolean => {
        return this.checkLocationPath("/offboarding");
    };

    shouldHideTopBar = (): boolean => {
        return (
            this.isAuthenticationRoute() ||
            this.checkLocationPath("/ref") ||
            this.checkLocationPath("/change-email") ||
            this.checkLocationPath("/composer") ||
            this.checkLocationPath("/pages/create") ||
            this.checkLocationPath("/pages/edit") ||
            (this.checkLocationPath("/interactive-video") &&
                !this.checkLocationPath("/interactive-videos")) ||
            this.checkLocationPath("/sequence") ||
            (!this.loginHelperService.isUserLoggedIn() && !this.checkLocationPath("/offboarding")) ||
            this.checkLocationPath("/congrats") ||
            this.checkLocationPath("/offers") ||
            this.checkLocationPath("/background") ||
            this.checkLocationPath("/export-video") ||
            this.checkLocationPath("/download-mp4")
        );
    };

    checkLocationPath = (equalsTo: string) => {
        return this.location.path().substring(0, equalsTo.length) === equalsTo;
    };

    includePath = (equalsTo: string) => {
        return this.location.path().substring(0, equalsTo.length).includes(equalsTo);
    };

    isAuthenticationRoute = (): boolean => {
        return (
            this.checkLocationPath("/login") ||
            this.checkLocationPath("/register") ||
            this.checkLocationPath("/forgot-password") ||
            this.checkLocationPath("/appsumo/register") ||
            this.checkLocationPath("/appsumo/congrats") ||
            this.checkLocationPath("/appsumo/collections/register") ||
            this.checkLocationPath("/redeem/register") ||
            this.checkLocationPath("/redeem/congrats") ||
            this.checkLocationPath("/sakiapp/register") ||
            this.checkLocationPath("/sakiapp/congrats") ||
            this.checkLocationPath("/confirm-password") ||
            this.checkLocationPath("/new-password") ||
            this.checkLocationPath("/activation") ||
            this.checkLocationPath("/welcome") ||
            this.checkLocationPath("/onboarding")
        );
    }

    back = (to: string = environment.globals.firstAppPage) => {
        this.history.pop()
        if (this.history.length > 0 && !this.checkLocationPath('/profile') && !this.checkLocationPath('/admin')) {
            this.location.back();
            this.history.length = 0;
        } else {
            this.router.navigate([to]);
        }
    };

    clearHistoryState = () => {
        this.history.length = 0;
    }

    sendMyTeamRouteEvent = (from: 'top' | 'left' | 'dropdown'): void => {
        this.eventsService.log(this.eventsService.events.navigation.goToMyTeam, {area: from});
    }

    navigateTo = (path: string): Promise<any> => {
        return this.router.navigate([path])
    }

    getCurrentPath = () => {
        return this.location.path();
    }


    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }
}
